.memberProfile {
  .remark_btn
  {
    display: inline-block;
    margin: 5px;
    width: 24px;
    height: 24px;
    background: url('../images/mobile/02_membership/04_profile/icon_exclamation.png') center center no-repeat;
    background-size: 100%;
  }

  .popup
  {
    position: absolute;
    left: 0;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    background: #fff;
    border: 1px solid #cccccc;
    box-shadow: 0px 0 15px #ddd;
    padding: 15px 15px 5px;
    z-index: 101;
    max-height: 300px;
    overflow-y: auto;

    > dl
    {
      display: block;
      margin: 0;
      padding: 0;

      dt
      {
        width: 25%;
        float: left;
        margin: 0 0 5px 0;
        padding: 0 5px 0 0;
        color: #a0a0a0;
        font-weight: normal;
      }

      dd
      {
        width: 75%;
        float: left;
        margin: 0 0 5px 0;
        padding: 0 0 0 5px;
        text-align: right;
        color: #a0a0a0;
      }
    }
  }
}
