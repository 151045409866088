.formHourly {
  background: #fff9d9 url('../images/desktop/banner/list-a-space.jpg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;

  @media (max-width: 840px) {
    .page-title {
      background: url('../images/mobile/General/banner/bg_03.jpg') center top no-repeat;
      background-size: auto 100%;
    }
  }

  .content {
    margin: 0;

    h3 {
      color: #000;
      font-size: 22px;
    }

    .wrapper-map {
      width: 100%;
      padding-bottom: 45%;
      position: relative;
      display: block;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: 100%;
        height: 100%;
        border: 0;
        z-index: 101;
      }
    }

    .wrapper-tabs {
      .tab-content {
        background: #f3f3f3;
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .rbc-date-cell {

    .event {

      color: #1dbeb9;
    }
  }

  .rbc-current {

    .today {

      display: none;
    }
  }

  .rbc-event {

    position: relative;
    top: -50%;

    .rbc-event-content {

      display: none !important;
    }
  }
}


.rbc-event-content div {

  white-space: pre-line;
}
