//Profile
#myAccountProfile {

  #profile_pic {

    .profile_pic_block {

      position: relative;
      width: 150px;

      .profile_pic_display {

        width: 150px;
        height: 150px;
        border-radius: 150px;
        border: 1px grey solid;
        z-index: -1;
      }

      #edit_profile_pic {

        display: none;
      }

      .edit_profile_pic_btn {

        text-align: center;
        display: block;
        margin: 0 35px;
      }
    }
  }

  .oneTimePassword,
  .vehicleRegMark {

    //.title {
    //
    //  position: absolute;
    //  top: 10px;
    //
    //  @media screen and (max-width: 640px) {
    //
    //    position: relative;
    //  }
    //}

    .vehiclesInput {

      position: relative;

      .remove {

        position: absolute;
        width: 33px;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .button {

      width: 100%;
      height: 56px;
      margin: 0 auto;
      text-align: center;
      line-height: 34px;
    }

    .whiteBtn {

      background-color: transparent;
      border: solid 1px #cccccc;
      color: #1dbeb9;
    }
  }

  .margin_left_right {

    margin: 10px 0px;

    @media screen and (min-width: 840px) {

      margin: 0px 10px;
    }
  }

  .mobile-full {

    @media screen and (max-width: 840px) {

      width: 100%;
    }
  }

  .viewProfileBlock {

    position: relative;

    .viewProfile {

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.memberSettings {
  background: #fff9d9 url('../images/desktop/banner/list-a-space.jpg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;

  @media (max-width: 840px) {
    background: url('../images/mobile/General/banner/bg_01.jpg') center top no-repeat;
    background-size: 100%;
  }

  .content {
    margin: 0;

    h3 {
      color: #000;
      font-size: 22px;
    }

    .wrapper-map {
      width: 100%;
      padding-bottom: 45%;
      position: relative;
      display: block;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: 100%;
        height: 100%;
        border: 0;
        z-index: 101;
      }
    }
  }

  // ALERT
  .memberAlert {

  }

  // PAYMENT
  .memberPayment {
    .border-box {
      background: #f3f3f3;
      padding: 20px;

      .button {
        margin: 0;
      }
    }

    .card_list {
      table {
        margin: 0 0 30px;
        padding: 0;
        width: auto;
      }

      tr {
        margin: 0;
        padding: 0;
      }

      td {
        margin: 0;
        padding: 10px 0;
        line-height: 20px;
      }

      .numbering {
        color: #fff;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        display: inline-block;
        border-radius: 10px;
        background: #000;
        font-style: normal;
        margin-right: 15px;
      }

      button {
        background: none;
        border: 0;
        margin: 0 10px;

        &.default {
          border: 1px solid #1dbeb9;
          border-radius: 10px;
          padding: 0 10px;
        }

        &.delete {
          color: #1dbeb9;
        }
      }

      span {
        color: #1dbeb9;
      }
    }

    .form_wrapper {
      .mobile-full {
        @media (max-width: 840px) {
          width: 100%;
        }
      }

      .fifty.left {
        padding-right: 10px;
      }

      .fifty.right {
        padding-left: 10px;
      }

      @media (max-width: 840px) {
        .fifty.left {
          padding-right: 0;
        }
        .fifty.right {
          padding-left: 0;
        }
      }
    }

    .wrapper {
      position: relative;
      margin: 0;

      h4 {
        position: relative;
        margin: 0 0 20px 0;

        &.special {
          padding: 0 0 0 35px;

          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -13px;
            width: 24px;
            height: 24px;
            color: #fff;
            background: #000;
            border-radius: 20px;
            padding: 0px 7px;
            font-size: 16px;
          }
        }
      }

      .wrap {
        margin: 0 0 0 -20px;
        display: table;
        width: calc(100% + 20px);

        @media (max-width: 640px) {
          margin: 0 0 0 -15px;
          width: calc(100% + 15px)
        }

        > div {
          display: table-cell;
          vertical-align: middle;

          @media (max-width: 480px) {
            display: block;
            width: 100%;
          }
        }

        > div > div {
          margin: 0 0 20px 20px;

          @media (max-width: 640px) {
            margin: 0 0 20px 15px;
          }

          .button {
            margin: 0 0 0 0px;

            &:first-child {
              margin: 0;
            }
          }
        }
      }

      .cardIcons {
        position: absolute;
        right: -10px;
        top: -4px;
        display: table;

        span {
          display: table-cell;
        }
      }
    }

    .border-box {
      background: #f3f3f3;
      padding: 20px 30px;

      .button {
        margin: 0;
      }
    }

    .spacer {
      @media (max-width: 480px) {
        display: none;
      }
    }

    .update_btn {
      width: 200px;
    }
  }

  .loading {
    min-height: 500px;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .overlay {

    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 9990;
    display: none;

    &.active {

      display: unset;
    }
  }

  .deleteCard {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5rem;
    z-index: 9999;
    background-color: white;
    display: none;
    border: 5px solid #1dbeb9;
    max-width: 500px;

    &.active {

      display: unset;
    }

    .button {

      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.settingAlert {

  .title_font_size {

    font-size: 18px;
  }

  .inputRangeDiv {

    span {

      font-size: 15px;
    }
  }
}