.wrapper-container.review {

  .spaceInfo {

    border: 1px solid #ccc;
    padding: 20px;

    .person {

      text-align: right;

      .name {

        margin: 0;
        padding: 0.5rem 0;
      }

      .image {

        margin: 0;

        img {

          margin: 0 auto;
          border-radius: 60px;
        }
      }
    }
  }

  .reviewInfo {

    background: #f3f3f3;
    padding: 2rem 0;

    .reviewInfoContent {

      max-width: 800px;
      margin: 0 auto;
    }

    .form {

      text-align: center;
    }
  }
}

.reviewed {

  text-align: center;
}

.addReviewBlock {

  width: 120px;
  margin: 0 auto;
  text-align: center;

  .reviews {

    li {

      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}