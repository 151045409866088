.cancellation {

  .title {

    text-align: center;
  }

  li {

    margin: 1rem 0;
  }
}