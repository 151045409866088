.sortbyFilterMobile {
  list-style: none;
  display: table;
  margin: 0;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  z-index: 998;
  display: none;
  border-top: solid 1px #ccc;
  @include transition(0.1s);

  &.active {
    left: 300px;
    @include transition(0.1s);
  }

  @media (max-width: 980px) {
    display: table;
  }

  > li {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    width: 50%;
    border-left: solid 1px #ccc;

    &:first-child {
      border: none;
    }

    > span {
      display: block;
      cursor: pointer;
    }

    .menuSortby,
    .menuFilter {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: fixed;
      bottom: 50px;
      left: 0;
      width: 100%;
      overflow-y: auto;
      height: 0px;
      -webkit-overflow-scrolling: touch;
      @include transition(0.1s);

      &.active {
        height: auto;
        max-height: calc(100% - 100px);
        @include transition(0.1s);
      }

      &.left {
        left: 300px;
        @include transition(0.1s);
      }

      .button {
        display: block;
      }

      .contentSortby {
        background: $majorGreen;
        padding: 20px 20px;

        > ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: block;

          > li {
            display: block;
            margin: 10px 0 0 0;
            padding: 20px 15px 20px 70px;
            text-align: left;
            color: #fff;
            cursor: pointer;
            background-position: 20px 11px;
            background-repeat: no-repeat;

            &:hover,
            &:focus,
            &.active {
              color: $majorGreen;
              background: #fff;
              background-position: 20px -24px;
              background-repeat: no-repeat;
            }

            &:first-child {
              margin: 0;
            }

            &.price {
              background-image: url('../images/mobile/03_find_parking_space/btn_sorting_price.png');
            }

            &.rating {
              background-image: url('../images/mobile/03_find_parking_space/btn_sorting_rating.png');
            }

            &.distance {
              background-image: url('../images/mobile/03_find_parking_space/btn_sorting_distance.png');
            }

            &.review {
              background-image: url('../images/mobile/03_find_parking_space/btn_sorting_reviews.png');
            }
          }
        }
      }

      .contentFilter {
        background: #fff;
        padding: 20px 20px;

        > h5 {
          font-weight: bold;
          margin: 0 0 10px 0;
          @media (max-width: 640px) {
            margin: 0 0 10px 0;
          }
        }

        .booking-options {
          list-style: none;
          display: table;
          margin: 0 0 20px -20px;
          padding: 0;
          width: calc(100% + 20px);

          @media (max-width: 640px) {
            margin: 0 0 10px -10px;
            width: calc(100% + 10px)
          }

          > div {
            display: table-cell !important;

            li {
              display: block;
              margin: 0 0 0 20px;
              padding: 0 0 15px 0;
              text-align: center;
              cursor: pointer;

              @media (max-width: 640px) {
                margin: 0 0 0 10px;
                padding: 0 0 5px 0;
              }

              &.active {
                background-color: $majorGreen;

                h5 {
                  color: #fff;
                }
              }

              .icon {
                width: 145px;
                height: 145px;
                display: inline-block;
                background-size: 100% !important;

                @media (max-width: 640px) {
                  width: 120px;
                  height: 120px;
                }
                @media (max-width: 480px) {
                  width: 80px;
                  height: 80px;
                }

                &.active {
                  background-color: $majorGreen !important;
                  background-position: center bottom !important;
                }

                &.privateCar {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_private_car.png') center top no-repeat;
                }

                &.van {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_van.png') center top no-repeat;
                }

                &.motorcycle {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_motor.png') center top no-repeat;
                }

                &.lorry {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_lorry.png') center top no-repeat;
                }

                &.others {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_other.png') center top no-repeat;
                }

                &.indoor {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_indoor.png') center top no-repeat;
                }

                &.withCovers {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_with_cover.png') center top no-repeat;
                }

                &.noCovers {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_no_cover.png') center top no-repeat;
                }

                &.ev {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_ev.png') center top no-repeat;
                }

                &.headRoom {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_head_room.png') center top no-repeat;
                }

                &.hourly {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_hourly.png') center top no-repeat;
                }

                &.monthly {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_monlthy.png') center top no-repeat;
                }

                &.timeShare {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_time_share.png') center top no-repeat;
                }
              }

              h5 {
                text-align: center;
                color: $majorGreen;
                font-size: 14px;
                margin: -15px 0 0 0;
                padding: 0 10px 10px 10px;
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
            }

            input[type=text],
            select {
              max-width: 100px;
              padding: 5px 10px;
              height: auto !important;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}


.mapView {
  .hideFilter {
    .sortbyFilterDesktop {
      left: -700px !important;
    }
  }

  .sortbyFilterDesktop {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    z-index: 998;
    @include transition(0.1s);

    @media (max-width: 980px) {
      display: none;
    }

    > li {
      display: block;
      margin: 0;
      padding: 0;
      text-align: center;
      border-bottom: 1px solid #fff;

      > span {
        display: block;
        cursor: pointer;
        background: #f3f3f3 url('../images/mobile/General/menu-and-footer/btn_arrow-footer.png') center right 20px no-repeat;
        color: black;
        padding: 15px 45px 15px 20px;
        text-align: right;

        &.active {
          background-color: $majorGreen;
          background-image: url('../images/mobile/General/menu-and-footer/btn_arrow-footer-on.png');
          color: #fff;
        }
      }

      .menuSortby,
      .menuFilter {
        list-style: none;
        margin: 0;
        padding: 0;
        display: none;
        @include transition(0.1s);

        &.active {
          display: block;
          @include transition(0.1s);
        }

        .button {
          display: block;
          border-radius: 0;
          margin: 0;
        }

        .contentSortby {
          background: $majorGreen;
          padding: 10px 20px 20px;

          > ul {
            list-style: none;
            margin: 0 0 0 -10px;
            padding: 0;
            display: block;

            li {
              display: block;
              margin: 10px 0 0 10px;
              padding: 20px 15px 20px 70px;
              text-align: left;
              color: #fff;
              cursor: pointer;
              background-position: 20px 11px;
              background-repeat: no-repeat;

              &:hover,
              &:focus,
              &.active {
                color: $majorGreen;
                background: #fff;
                background-position: 20px -24px;
                background-repeat: no-repeat;
              }

              // &:first-child { margin:0; }

              &.price {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_price.png');
              }

              &.rating {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_rating.png');
              }

              &.distance {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_distance.png');
              }

              &.review {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_reviews.png');
              }
            }
          }
        }

        .contentFilter {
          background: #f8f7f5;
          padding: 20px 20px;

          > h5 {
            font-weight: bold;
            margin: 0 0 10px 0;
            @media (max-width: 640px) {
              margin: 0 0 10px 0;
            }
          }

          .booking-options {
            list-style: none;
            display: table;
            margin: 0 0 20px -10px;
            padding: 0;
            width: calc(100% + 10px);

            @media (max-width: 640px) {
              margin: 0 0 10px -10px;
              width: calc(100% + 10px)
            }

            &.special {
              max-width: 392px;
              margin: 0 auto 0 70px;
            }

            > div {
              display: table-cell !important;

              li {
                display: block;
                margin: 0 0 10px 10px;
                padding: 0 0 5px 0;
                text-align: center;
                cursor: pointer;

                @media (max-width: 640px) {
                  margin: 0 0 0 10px;
                  padding: 0 0 5px 0;
                }

                &.active {
                  background-color: $majorGreen;

                  h5 {
                    color: #fff;
                  }
                }

                .icon {
                  width: 90px;
                  height: 90px;
                  display: inline-block;
                  background-size: 100% !important;

                  &.active {
                    background-color: $majorGreen !important;
                    background-position: center bottom !important;
                  }

                  &.privateCar {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_private_car.png') center top no-repeat;
                  }

                  &.van {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_van.png') center top no-repeat;
                  }

                  &.motorcycle {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_motor.png') center top no-repeat;
                  }

                  &.lorry {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_lorry.png') center top no-repeat;
                  }

                  &.others {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_other.png') center top no-repeat;
                  }

                  &.indoor {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_indoor.png') center top no-repeat;
                  }

                  &.withCovers {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_with_cover.png') center top no-repeat;
                  }

                  &.noCovers {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_no_cover.png') center top no-repeat;
                  }

                  &.ev {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_ev.png') center top no-repeat;
                  }

                  &.headRoom {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_head_room.png') center top no-repeat;
                  }

                  &.hourly {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_hourly.png') center top no-repeat;
                  }

                  &.monthly {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_monlthy.png') center top no-repeat;
                  }

                  &.timeShare {
                    background: url('../images/mobile/03_find_parking_space/filter/btn_time_share.png') center top no-repeat;
                  }
                }

                h5 {
                  text-align: center;
                  color: $majorGreen;
                  font-size: 14px;
                  margin: -15px 0 0 0;
                  padding: 0 10px 10px 10px;
                  @media (max-width: 480px) {
                    font-size: 12px;
                  }
                }
              }

              input[type=text],
              select {
                max-width: 100px;
                padding: 5px 10px;
                height: auto !important;
                font-size: 12px;
              }
            }
          }
        }
      }

      .menuSearch {
        list-style: none;
        margin: 0;
        padding: 0;
        display: none;
        @include transition(0.1s);

        &.active {
          display: block;
          @include transition(0.1s);
        }

        .contentSearch {
          padding: 20px;
          background: #f3f3f3;

          .search {
            padding: 10px 20px 0 20px;
            border: solid 1px #ccc;
            background: #fff;

            .wrap {
              margin: 0 0 0 -30px;

              > div.fifty {
                @media (max-width: 640px) {
                  width: 100%;
                }
              }

              .sep-0 {
                border-bottom: solid 1px #ccc;
                margin: 0 0 10px 30px;
              }

              > div > div {
                margin: 0 0 10px 30px;
                position: relative;

                .vertical-sep {
                  position: absolute;
                  width: 1px;
                  height: 100%;
                  top: 0;
                  right: -15px;
                  z-index: 2;
                  background: #ccc;
                  @media (max-width: 640px) {
                    display: none;
                  }
                }

                select,
                input {
                  border: none;
                  padding: 15px 0;
                }

                h5 {
                  color: $majorYellowGreen;
                  text-transform: uppercase;

                  &.toHide {
                    @media (max-width: 640px) {
                      display: none;
                    }
                  }
                }

                .button {
                  width: 100%;
                  background: $majorYellowGreen;
                  padding: 25px;
                  font-size: 18px;
                }

                .bell {
                  position: relative;

                  &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 1px;
                    height: 100%;
                    right: -10px;
                    top: 0;
                    background: #ccc;
                  }

                  display: inline-block;
                  width: 30px;
                  float: left;
                  margin: 13px 20px 0 0;
                }

                input[type=text] {
                  width: 100%;
                  float: left;

                  &.short {
                    width: calc(100% - 85px);
                  }
                }

                .magnifier {
                  width: 20px;
                  height: 20px;
                  float: left;
                  margin: 18px 0 0 10px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .wrapper {
      padding: 0px;

      .mobile_list_view {
        border-bottom: solid 1px #ccc;
        padding: 5px 5px 4px 5px;

        &.selected {
          border: 5px solid #1dbeb9;
          padding: 0;
        }
      }
    }
  }
}

.sortbyFilterDesktop2 {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 0px;
  overflow: hidden;

  &.active {
    height: auto;
  }

  @media (max-width: 980px) {
    display: none;
  }

  > li {
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;

    > span {
      display: block;
      cursor: pointer;
      background: $majorGreen;
      color: #fff;
      padding: 30px 20px 10px 20px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;

      &.reverse {
        background: #f8f7f5;
        color: $majorGreen;
      }
    }

    .menuSortby,
    .menuFilter {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: #f8f7f5;
      @include transition(0.1s);

      &.active {
        display: block;
        @include transition(0.1s);
      }

      .button {
        display: block;
        border-radius: 0;
        margin: 0;
      }

      .contentSortby {
        background: $majorGreen;
        padding: 10px 20px 20px;

        > ul {
          list-style: none;
          margin: 0 0 0 -10px;
          padding: 0;
          display: block;

          > div {
            @media (max-width: 1200px) {
              width: 50%;
            }

            li {
              display: block;
              margin: 10px 0 0 10px;
              padding: 20px 15px 20px 70px;
              text-align: left;
              color: #fff;
              cursor: pointer;
              background-position: 20px 11px;
              background-repeat: no-repeat;


              &:hover,
              &:focus,
              &.active {
                color: $majorGreen;
                background: #fff;
                background-position: 20px -24px;
                background-repeat: no-repeat;
              }

              // &:first-child { margin:0; }

              &.price {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_price.png');
              }

              &.rating {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_rating.png');
              }

              &.distance {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_distance.png');
              }

              &.review {
                background-image: url('../images/mobile/03_find_parking_space/btn_sorting_reviews.png');
              }
            }
          }
        }
      }

      .contentFilter {
        background: #f8f7f5;
        padding: 20px 20px;
        max-width: 800px;
        margin: 0 auto;

        > h5 {
          font-weight: bold;
          margin: 0 0 10px 0;
          @media (max-width: 640px) {
            margin: 0 0 10px 0;
          }
        }

        .booking-options {
          list-style: none;
          display: table;
          margin: 0 0 20px -10px;
          padding: 0;
          width: calc(100% + 10px);

          @media (max-width: 640px) {
            margin: 0 0 10px -10px;
            width: calc(100% + 10px)
          }

          &.special {
          }

          > div {
            display: table-cell !important;

            li {
              display: block;
              margin: 0 0 10px 10px;
              padding: 0 0 5px 0;
              text-align: center;
              cursor: pointer;

              @media (max-width: 640px) {
                margin: 0 0 0 10px;
                padding: 0 0 5px 0;
              }

              &.active {
                background-color: $majorGreen;

                h5 {
                  color: #fff;
                }
              }

              .icon {
                width: 90px;
                height: 90px;
                display: inline-block;
                background-size: 100% !important;

                &.active {
                  background-color: $majorGreen !important;
                  background-position: center bottom !important;
                }

                &.privateCar {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_private_car.png') center top no-repeat;
                }

                &.van {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_van.png') center top no-repeat;
                }

                &.motorcycle {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_motor.png') center top no-repeat;
                }

                &.lorry {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_lorry.png') center top no-repeat;
                }

                &.others {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_other.png') center top no-repeat;
                }

                &.indoor {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_indoor.png') center top no-repeat;
                }

                &.withCovers {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_with_cover.png') center top no-repeat;
                }

                &.noCovers {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_no_cover.png') center top no-repeat;
                }

                &.ev {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_ev.png') center top no-repeat;
                }

                &.headRoom {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_head_room.png') center top no-repeat;
                }

                &.hourly {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_hourly.png') center top no-repeat;
                }

                &.monthly {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_monlthy.png') center top no-repeat;
                }

                &.timeShare {
                  background: url('../images/mobile/03_find_parking_space/filter/btn_time_share.png') center top no-repeat;
                }
              }

              h5 {
                text-align: center;
                color: $majorGreen;
                font-size: 14px;
                margin: -15px 0 0 0;
                padding: 0 10px 10px 10px;
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
            }

            input[type=text],
            select {
              max-width: 100px;
              padding: 5px 10px;
              height: auto !important;
              font-size: 12px;
            }
          }
        }

        .wrap {
          margin: 0 0 0 -40px;

          > div > div {
            margin: 0 0 0 40px;
          }
        }
      }
    }

    .menuSearch {
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      @include transition(0.1s);

      &.active {
        display: block;
        @include transition(0.1s);
      }

      .contentSearch {
        padding: 20px;
        background: #f3f3f3;

        .search {
          padding: 10px 20px 0 20px;
          border: solid 1px #ccc;
          background: #fff;

          .wrap {
            margin: 0 0 0 -30px;

            > div.fifty {
              @media (max-width: 640px) {
                width: 100%;
              }
            }

            .sep-0 {
              border-bottom: solid 1px #ccc;
              margin: 0 0 10px 30px;
            }

            > div > div {
              margin: 0 0 10px 30px;
              position: relative;

              .vertical-sep {
                position: absolute;
                width: 1px;
                height: 100%;
                top: 0;
                right: -15px;
                z-index: 2;
                background: #ccc;
                @media (max-width: 640px) {
                  display: none;
                }
              }

              select,
              input {
                border: none;
                padding: 15px 0;
              }

              h5 {
                color: $majorYellowGreen;
                text-transform: uppercase;

                &.toHide {
                  @media (max-width: 640px) {
                    display: none;
                  }
                }
              }

              .button {
                width: 100%;
                background: $majorYellowGreen;
                padding: 25px;
                font-size: 18px;
              }

              .bell {
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  display: block;
                  width: 1px;
                  height: 100%;
                  right: -10px;
                  top: 0;
                  background: #ccc;
                }

                display: inline-block;
                width: 30px;
                float: left;
                margin: 13px 20px 0 0;
              }

              input[type=text] {
                width: 100%;
                float: left;

                &.short {
                  width: calc(100% - 85px);
                }
              }

              .magnifier {
                width: 20px;
                height: 20px;
                float: left;
                margin: 18px 0 0 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .wrapper {
    padding: 20px;
  }
}