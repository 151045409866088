.memberCredit { background:#cee0ea;

    .wrapper-redeem{ margin:0 0 60px 0; padding:30px 20px 0 20px; background:$majorGreen;

        @media (max-width: 980px) { padding:30px 20px 0 20px; }
        
        h4{ color:#fff; font-size:16px; font-weight:normal; margin:0 0 10px 0;
            span { font-weight:bold; }
        }
        
        .redeem {  margin:0 0 0 -20px;
            
            > div {
                @media (max-width: 980px) { width:100%; }
            }
            
            > div > div { margin:0 0 30px 20px; }
        }
    }

    .shareWithFriends { background:#f3f3f3; padding:10px 20px; margin:0 0 30px 0; text-align:center;
        // using component
    }
    
    .content{ margin:0 0 0 -60px;

        @media (max-width: 1200px) { margin:0 0 0 -20px; }

        > div {
            @media (max-width: 1024px) { width:50%; }
            @media (max-width: 980px) { width:100%; }
        }
        .sepThree { display:block; 
            @media (max-width: 1024px) { display:none; }
        }
        .sepTwo { display:none;
            @media (max-width: 1024px) { display:block; }
        }

        > div > div { margin:0 0 20px 60px; background:#f3f3f3; 

            @media (max-width: 1200px) { margin:0 0 20px 20px; }
        }   
    }
}
