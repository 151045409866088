.memberCreateAccount {
  background: #e0e0e0;

  .content {
    margin: 0 auto;
    max-width: 740px;

    .door {
      display: inline-block;
      width: 52px;
      height: 52px;
      margin: 0 0 20px 0;
      background: url('../images/mobile/02_membership/00_signin/icon_login.png');
      background-size: 100%;
    }

    h2 {
      margin: 0 0 5px 0;
      font-size: 30px;
      font-weight: bold;
      color: #000;
    }

    p {
      margin: 0 0 15px 0;
      font-size: 16px;
      color: #000;
    }
  }
}

.memberCreateAccountType {
  background: #e0e0e0;

  .content {
    margin: 0 auto;
    max-width: 620px;

    .door {
      display: inline-block;
      width: 52px;
      height: 52px;
      margin: 0 0 20px 0;
      background: url('../images/mobile/02_membership/00_signin/icon_login.png');
      background-size: 100%;
    }

    h2 {
      margin: 0 0 5px 0;
      font-size: 30px;
      font-weight: bold;
      color: #000;
    }

    p {
      margin: 0 0 15px 0;
      font-size: 16px;
      color: #000;
    }

    .registerTypes {
      text-align: center;

      > span {

      }

      .registerTypeBtn {
        border: 1px solid #ccc;
        display: inline-block;
        width: 70%;
        text-align: center;
        border-radius: 10px;
        margin: 0 auto;
        padding: 30px 0;
        font-size: 20px;
        font-weight: bold;
        color: #1dbeb9;

        &.corporate {
          color: #2B918D;
        }

        img {
          display: inline-block;
          height: 174px;
          width: auto !important;
          margin-bottom: 20px;
        }
      }

      @media (max-width: 860px) {
        .registerTypeBtn {
          width: 90%;
          padding: 15px 0;
          font-size: 18px;

          img {
            height: 120px;
            margin-bottom: 10px;
          }
        }
      }

      @media (max-width: 640px) {
        .registerTypeBtn {
          font-size: 16px;

          img {
            height: 100px;
          }
        }
      }
    }
  }
}

.register_icon {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 3px;
  width: 40px;

  img {

    position: relative;
    margin: 0 auto;
    height: 25px;
  }
}