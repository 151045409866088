.contact {
  background: #fff9d9 url('../images/desktop/banner/Contact Us.jpg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;

  .content {
    margin: 0;

    .form {
      width: 60%;
      padding: 0 60px 0 0;

      @media (max-width: 980px) {
        width: 100%;
        padding: 0 0 30px 0;
      }
    }

    .methods {
      width: 40%;
      padding: 0 0 10px 55px;
      border-left: solid 1px #ccc;

      @media (max-width: 980px) {
        width: 100%;
        padding: 30px 0 30px 0;
        border: none;
        border-bottom: solid 1px #ccc;
      }

      h3 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 5px 0;
      }

      img {
        margin: 0 0 10px 0;
      }

      p {
        color: #717171;
        font-size: 16px;
        margin: 0;
      }
    }
  }
}
