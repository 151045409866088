.popup-plane { position:fixed; top:0; left:0; right:0; width:100%; height:100%; background:url('../images/bg-black-80.png') 0 0 repeat; z-index:1001; display:table; 
    
    display:none; opacity:0;

    >.inner { display:table-cell; vertical-align:middle; text-align:center;

        >.wrap { position:relative; display:inline-block; width:100%; max-width: 690px; max-height:780px; margin:0; overflow-y:auto; -webkit-overflow-scrolling: touch; background:#fff;

            >.content { padding:25px; text-align:left;

                @media (max-width: 640px) { padding:30px 20px; }

                >.holder { 

                    .control { margin : 0 0 35px 0;
                        .back { width:30px; height:30px; display:block; background:url('../images/mobile/General/menu-and-footer/btn_share.png') center center no-repeat; background-size:100%; cursor: pointer;}
                    }

                    .wrapper { position:relative; margin:0; padding:0 40px;

                        @media (max-width: 640px ) { padding:0; }

                        h4 { position:relative; margin:0 0 30px 0; color:#000;
        
                            &.special { padding: 0 0 0 35px;
                                span { display:block; position:absolute; top:50%; left:0; margin-top:-13px; width:24px; height:24px; color:#fff; background:#000; border-radius:20px; padding:0px 7px; font-size:16px; }
                            }
                        }
                    
                        .wrap { margin:0 0 0 -20px; display:table; width:calc(100% + 20px);
        
                            @media (max-width: 640px ) { margin:0 0 0 -15px; width:calc(100% + 15px) }
        
                            > div { display:table-cell; vertical-align:middle; 
                                
                                @media (max-width: 480px ) { display:block; width:100%; }
                            }
        
                            > div > div { margin:0 0 20px 20px; 
                                
                                @media (max-width: 640px ) { margin:0 0 20px 15px; }
        
                                .button { margin:0 0 0 10px; width:100%; text-align:center; padding:15px 10px;
                                    &:first-child{ margin:0; }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .popup-close { width:40px; height:40px; background:rgba(0,0,0, 0.7); border-radius:50%; position:relative; cursor:pointer; }
}

.popup-contents { display:none; }
