
// Body
$bodyBgColor: white;

// Typography
$font-family-body: 'Open Sans', sans-serif;
$font-family-titles: 'Sintony', sans-serif;
$font-size-base: 15px;
$line-height-base: 1.2em;

$font-family-body-chinese: 'Microsoft JhengHei','Open Sans', sans-serif;
$font-family-titles-chinese: 'Microsoft JhengHei','Sintony', sans-serif;

// Define website basic styles
$bodyColor:#484848;

$aColor:#484848;
$aHoverColor:#484848;
$borderColor :#757575; // accordion

// REAL
$majorGreen :#1dbeb9;
$majorYellowGreen :#c0d032;
$majorGrey: #a1a1a1;