.memberWishList,
.memberBookings {
  background: #e0e0e0;

  .paddingFix {
    padding-top: 0;
  }

  .content {
    margin: 0;

    .wrapper-tabs {

      ul.nav-tabs {
        display: table;
        width: 100%;
        margin: 45px 0 45px 0;
        //width:calc(100% + 140px);
        //margin: 0 0 45px -70px;

        @media (max-width: 640px) {
          width: calc(100% + 40px);
          margin: 0 0 20px -20px;
        }

        > li {
          margin: 0;
          float: none;
          display: table-cell;
          width: 33.34%;
          vertical-align: middle;
          background: #bfbfbf;
          border-right: solid 1px #e1e1e1;

          &:first-child {
            @media (max-width: 1500px) {
              border-left: solid 1px #e1e1e1;
            }
          }

          &.active {
            background: $majorGreen;
          }

          > a {
            display: block;
            color: white;
            font-size: 24px;
            font-weight: normal;
            text-transform: uppercase;
            line-height: 1.2em;
            padding: 15px;
            text-align: center;
            border-radius: 0;
            word-spacing: 9999999px;
            @media (max-width: 980px) {
              font-size: 20px;
            }
            @media (max-width: 640px) {
              font-size: 16px;
            }
            @media (max-width: 480px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .carTypes {

    max-width: unset;
  }

  .information {

    position: relative;

    .get_direction {

      position: absolute;
      top: 10px;
      right: 0;
      padding: 10px 20px;
    }
  }
}

.memberWishList .content .wrapper-tabs ul.nav-tabs > li {
  width: 33.34%;
}

.memberBookings .content .wrapper-tabs ul.nav-tabs > li {
  width: 50%;
}

.profileFilter {

  margin-bottom: 10px;

  .filterTitleBlock {

    width: 100px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;

    &.active {

      .filterTitleArrow {

        transform: rotate(45deg);
      }
    }

    .filterTitle {
      font-size: 13px;
      margin-bottom: 5px;
    }

    .filterTitleArrow {

      border-left: 1px solid #484848;
      border-top: 1px solid #484848;
      width: 10px;
      height: 10px;
      margin: 0 auto;
      transform: rotate(-135deg);
    }
  }

  .filterBlock {

    display: none;

    &.active {

      display: block;
    }

    .checkboxFive {

      display: inline;
      margin: 0 1.5rem 0 1rem;
      width: auto;
      height: auto;

      input {
        display: none;
      }

      > div {

        width: auto;
        height: auto;

        > label {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: #fff;
          border: 1px solid #cccccc;
          border-radius: 12px;
          position: relative;
          z-index: 0;
          cursor: pointer;
          line-height: 1em;

          &:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #a1a1a1;
            border-radius: 6px;
            top: 6px;
            left: 6px;
            border: 0;
          }
        }
      }
    }
  }
}
