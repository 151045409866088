.messages { background:grey;

    .spaceInfo  { border:solid 1px #cccccc; padding:15px; margin: 0 0 20px 0;

        .space { width:calc(100% - 250px);

            @media (max-width: 840px) { width:100%; }

            h3 { margin:0 0 4px 0; padding:0; font-size:22px; font-weight:bold; color:#000; }

            h4 { margin:0 0 20px 0; padding:0; font-size:16px; font-weight:normal; color:#717171;

                @media (max-width: 840px) { width:100%; }
            }

            .spaceId { color:#6d6d6d; font-size:14px; }
        }
        .person { text-align:right; width:250px;

            @media (max-width: 840px) { width:100%; text-align:left; margin: 0 0 20px 0; }

            >.image { width:60px; border-radius:30px; overflow:hidden; display:inline-block; margin:0 0 7px 0;
                img { width:100%; height:60px; display:block;}
            }

            h3 { color:#000; font-size:16px; margin:0 0 3px 0; }

            .memberSince { color:#6f6f6f; margin:0; font-size:13px;
                span { white-space:nowrap;  }
            }
        }
    }

    .enquiry  { background: #eeeeee; padding:15px; margin: 0 0 20px 0;

        h4 { margin:0 0 4px 0; padding:0; font-weight:bold; color:#000; }

        h5 { margin:0 0 20px 0; padding:0; font-weight:normal; color:#717171; white-space: pre-line;
            @media (max-width: 840px) { width:100%; }
        }

        textarea { margin:10px 0 10px 0; min-height:200px; }
    }

    .response { border:solid 1px $majorGreen; padding:20px; margin: 0 auto 20px; border-radius:3px; max-width:700px;

        .info {

            &.border { border-bottom:solid 1px $majorGreen; padding-bottom:25px; }

            h5 { color:$majorGreen; font-size:14px; margin:0 0 15px 0; }

            .image { width:215px; float:left;

                @media (max-width: 980px) { width:100%; float:none; }

                img { width:100%; max-height:160px; display:block;

                    @media (max-width: 980px) { width:100%; max-height:none; }
                }
            }

            .text { width:calc(100% - 215px); float:left; padding: 0 0 0 20px;

                @media (max-width: 980px) { width:100%; float:none; padding:10px 0 0 0; }

                h3 { margin:0 0 5px 0; padding:0; font-size:20px; font-weight:bold; color:#000; }

                h4 { margin:0 0 8px 0; padding:0; font-size:14px; font-weight:normal; color:#717171; }

                span { display:block; color:$majorGreen; margin: 0 0 4px 0; }
            }

            dl { display:block; margin:0; padding:0;
                dt { width:50%; float:left; margin: 0 0 5px 0; padding:0 10px 0 0; color:#a0a0a0; font-weight:normal;
                    @media (max-width: 480px) { width:100%; padding:0; }

                    h4 { color:#a0a0a0; margin:0 0 5px 0; font-weight:normal; font-size:15px; }
                    h5 { color:#000; margin:0 0 10px 0; font-weight:normal; font-size:15px; }
                }
                dd { width:50%; float:left; margin: 0 0 5px 0; padding:0 0 0 10px;text-align:left; color:#a0a0a0;
                    @media (max-width: 640px) { text-align:right; }
                    @media (max-width: 480px) { width:100%; padding:0; text-align:left; }

                    h4 { color:#a0a0a0; margin:0 0 5px 0; font-weight:normal; font-size:15px; }
                    h5 { color:#000; margin:0 0 10px 0; font-weight:normal; font-size:15px; }
                }
            }

            .button { min-width:240px; margin:0; padding:15px; }
        }
    }

    .dialogues {

        .wrapper-message { margin:0 0 30px 0;
            &.mine { text-align:right;
                .message { max-width:580px; display:inline-block; text-align:right;
                    .content { background:$majorGreen; padding:20px; color:#fff; border-radius:3px; display:inline-block; text-align:left; }
                }
            }
            &.him { text-align:left;
                .message { max-width:580px; display:inline-block; text-align:left;
                    .content { background:#ebebeb; padding:20px; color:#000; border-radius:3px; display:inline-block; text-align:left;}
                }
            }
            .dateTime { display:block; color:#717171; font-size:14px; margin:0 0 10px 0; text-align:left; }
        }

        .wrapper-messageEntry { position:relative; padding-right:90px;
            .messageEntry { resize: none; }

            .messageSend {
                position:absolute; right:0px; bottom:-7px; background:$majorGreen; cursor:pointer; z-index:101;
            }
        }
    }

    .wrapper-formArea { background:#f3f3f3; padding:50px 55px 20px;

        @media (max-width: 640px) { padding:20px; }

        .formArea { margin:0 0 0 -20px;

            > div {
                @media (max-width: 840px) { width:100%; }
            }

            > div > div { margin:0 0 0 20px;

                h4 { position:relative; margin:0 0 20px 0;

                    &.special { padding: 0 0 0 35px;
                        span { display:block; position:absolute; top:50%; left:0; margin-top:-13px; width:24px; height:24px; color:#fff; background:#000; border-radius:20px; padding:0px 7px; font-size:16px; }
                    }
                }

                .wrap { margin:0 0 0 -20px; display:table; width:calc(100% + 20px);

                    @media (max-width: 640px ) { margin:0 0 0 -15px; width:calc(100% + 15px) }

                    > div { display:table-cell; vertical-align:middle;

                        @media (max-width: 480px ) { display:block; width:100%; }
                    }

                    > div > div { margin:0 0 20px 20px;

                        @media (max-width: 640px ) { margin:0 0 20px 15px; }

                        .button { padding:10px 50px;
                        }
                    }
                }
            }
        }
    }

    .wrapper-tabs { text-align:right;

        select.readStatus{ display:inline-block; float:left; width:auto; height:36px !important; font-size:14px; padding: 0px 40px 00px 40px; border-radius:0;
            option { background:url('../images/mobile/02_membership/01_wishlist/icon_type_3.png') left center no-repeat; }
        }

        ul.nav-tabs { border:solid 1px #ccc; margin:0; display:inline-block;
            >li { margin:0;
                >a { padding: 10px 40px; font-size:14px; font-weight:normal; border-radius: 0;
                    &[aria-selected="true"]{ background:$majorGreen; color:#fff; }
                }
            }
        }

        .tab-content { text-align:left; }
    }
}
