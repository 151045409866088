.wrapper-tabs {

  //Accordion
  .accordion {

    //position: relative;

    .accordion__title {

      background-color: #1dbeb9 !important;
      cursor: pointer;
    }

    .accordion__item {
      position: relative;
      margin-bottom: 1.5rem;
      font-size: 14px;
      line-height: 1.6em;

      .accordion__title {
        padding: 1rem;
        position: relative;
        padding-right: 30px;

        .arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          border-color: white transparent transparent transparent;
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%)
        }

        > h3 {
          color: white !important;
          margin: 0;
        }

        &[aria-selected="true"] {

          background-color: #1dbeb9;

          > h3 {

            //color: #FFF;
          }

          .arrow {
            border-width: 0 7px 10px 7px;
            border-color: transparent transparent white transparent;
          }
        }
      }

      .accordion__body {

        padding: 1rem;
        background-color: #f3f3f3;
      }
    }
  }
}