.benefits {
  background: #cac0b7 url('../images/desktop/banner/benefit.jpg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 0px;

}

.benefitsOf {
  margin: 0 0 0 -20px;
  padding: 0;

  > div {
    display: block !important;
    float: left;

    @media (max-width: 840px) {
      width: 100%;
    }

    li {
      display: block;
      margin: 0 0 50px 20px;
      padding: 0;
      text-align: center;

      .icon {
        width: 110px;
        height: 110px;
        border-radius: 55px;
        display: inline-block;
        background-size: 100%;

        &.active {
          background-position: center bottom !important;
        }

        &.SearchBook {
          background: url('../images/mobile/01_homepage/icon_01_01.png') center top no-repeat;
          background-size: 100%;
        }

        &.Verified {
          background: url('../images/mobile/01_homepage/icon_01_02.png') center top no-repeat;
          background-size: 100%;
        }

        &.Guarantee {
          background: url('../images/mobile/01_homepage/icon_01_03.png') center top no-repeat;
          background-size: 100%;
        }

        &.Free {
          background: url('../images/mobile/01_homepage/icon_02_01.png') center top no-repeat;
          background-size: 100%;
        }

        &.GetPaid {
          background: url('../images/mobile/01_homepage/icon_02_02.png') center top no-repeat;
          background-size: 100%;
        }

        &.Insurance {
          background: url('../images/mobile/01_homepage/icon_02_03.png') center top no-repeat;
          background-size: 100%;
        }

        &.CustomSupport {
          background: url('../images/mobile/06_about_us/icon_03_01.png') center top no-repeat;
          background-size: 100%;
        }

        &.Profile {
          background: url('../images/mobile/06_about_us/icon_03_02.png') center top no-repeat;
          background-size: 100%;
        }

        &.Notification {
          background: url('../images/mobile/06_about_us/icon_03_03.png') center top no-repeat;
          background-size: 100%;
        }
      }

      h5 {
        text-align: center;
        color: #000;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 20px 0 5px 0;
        padding: 0;
        @media (max-width: 640px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.4em;
        @media (max-width: 640px) {
          font-size: 16px;
        }
      }
    }
  }
}

.benefits_list_ahref {
  display: inline-block;
  width: 200px;
  text-align: center;
  vertical-align: middle;
}