.wrapper-calendar {
	height: 260px !important;
	position: relative;

	.rbc-calendar {

		.rbc-day-bg {
			background-color: #ccc;
		}
		.rbc-off-range-bg {
			background-color: #ccc;
		}

		.rbc-month-view {
			border: 0;
			border-bottom: 1px solid #ddd;
		}

		.rbc-header + .rbc-header {
			border-left: 0;
		}

		.rbc-month-row {
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
		}

		.rbc-date-cell {
			padding-right: 0;
			padding-left: 5px;
			text-align: left;

			span {
				font-size: 13px;
			}
		}

		.rbc-event {
			background: none;
			text-align: left;
			color: #1dbeb9;
			cursor: default;

			.rbc-event-content {
				font-size: 12px;
			}
		}
	}


	@media (min-width:480px) {
		height: 360px !important;
	}

	@media (min-width:780px) {
		height: 420px !important;
	}

	@media (min-width:1024px) {
		height: 500px !important;

		.rbc-calendar {
			.rbc-event {
				.rbc-event-content {
					font-size: 14px;
				}
			}
		}
	}

	@media (min-width:1280px) {
		.rbc-calendar {
			.rbc-event {
				.rbc-event-content {
					font-size: 16px;
				}
			}
		}
	}

}

.big_calendar_custom_editor {
	position: absolute;
	top: 50%;
	left: 0;
	right:0;
	margin:-100px auto 0;
	z-index: 5;
	background: #fff;
	width:80%;
	max-width: 400px;
	text-align: center;
	box-shadow: 0px 0 15px #999 !important;

	>div { position:relative;
		.btn-close { width:30px; height:30px; position: absolute; top:10px; right:10px; background:url('../images/mobile/General/icon_close.png') center center no-repeat; cursor:pointer; }

		.bc_date {
			background: #EEE;
			color: #000;
			padding: 15px;
			font-size:18px;
			font-weight:bold; 
		}
		.bc_content {
			padding: 20px;
	
			.bc_title {
				color: $majorGreen;
				font-size:16px;
			}
	
			.sep-40 { display:block; }
	
			.wrap { margin: 0 0 0 -20px !important; display: table !important; width: calc(100% + 20px) !important;
				>div {
					> div { background:yellow; margin: 0 0 0 20px !important;
						@media (max-width:640px) { margin: 10px 0 0 20px !important; }
		
						.button { width:100%; padding:15px 10px; }
					}
				}
			}
		}
	}
}

.customToolBar {text-align:center; margin:0 0 30px 0; position:relative; vertical-align:middle;
	.rbc-toolbar-label { text-transform:uppercase; font-weight:bold; font-size:20px; display:inline-block; width:200px; line-height:1em; 
		@media (max-width:640px) { width:160px; font-size:14px; }
	}
	
	.btn-back,
	.btn-next { display:inline-block; width:26px; height:26px;  text-indent:-9999px; border:0; 
		&:hover { background-position:bottom center; }
	}
	.btn-back { background:url('../images/mobile/04_list_a_space/btn_arrow_left.png') center top no-repeat; }
	.btn-next { background:url('../images/mobile/04_list_a_space/btn_arrow_right.png') center top no-repeat; }
}
