.payment {
  background: #e0e0e0;

  .content {
    margin: 0 0 0 -40px;

    > div {
      @media (max-width: 840px) {
        width: 100%;
      }
    }

    > div > div {
      margin: 0 0 20px 40px;

      .wrapper {
        position: relative;
        margin: 0 0 40px 0;

        h4 {
          padding: 0 0 0 35px;
          position: relative;
          margin: 0 0 20px 0;

          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -13px;
            width: 24px;
            height: 24px;
            color: #fff;
            background: #000;
            border-radius: 20px;
            padding: 0px 7px;
            font-size: 16px;
          }
        }

        .wrap {
          margin: 0 0 0 -20px;
          display: table;
          width: calc(100% + 25px);

          @media (max-width: 640px) {
            margin: 0 0 0 -15px;
            width: calc(100% + 15px)
          }

          > div {
            display: table-cell;
            vertical-align: middle;

            // @media (max-width: 640px ) { display:block; width:100%; }
          }

          > div > div {
            margin: 0 0 20px 20px;

            @media (max-width: 640px) {
              margin: 0 0 20px 15px;
            }

            .button {
              margin: 0 0 0 10px;

              &.first {
                margin: 0;
              }
            }
          }
        }

        .secure {
          display: table;

          span {
            display: table-cell;
            vertical-align: middle;
            padding: 0 0 0 30px;

            &:first-child {
              padding: 0;
            }

            img {
              width: auto;

              &.norton {
                max-width: 100px;
              }
            }
          }
        }

        .verifiedGuarantee {
          tr {
            td {
              vertical-align: middle;
              padding: 5px 0 5px 20px;
              font-size: 16px;
              line-height: 1.4em;

              a {
                color: $majorGreen;
                font-size: 16px;
                line-height: 1.4em;
              }

              &:first-child {
                padding: 5px 0 5px 0;
                width: 38px;
              }

              span {
                //color: $majorGreen;
              }
            }
          }

          img {
            width: 38px;
            height: 38px;
            margin: 0;
          }
        }

        .cardIcons {
          position: absolute;
          right: -10px;
          top: -4px;
          display: table;

          span {
            display: table-cell;
          }
        }
      }

      dl {
        display: block;
        margin: 0;
        padding: 0;

        dt {
          width: 50%;
          float: left;
          margin: 0 0 5px 0;
          padding: 0 10px 0 0;
          color: #a0a0a0;
          font-weight: normal;

          b {
            color: #000;
          }
        }

        dd {
          width: 50%;
          float: left;
          margin: 0 0 5px 0;
          padding: 0 0 0 10px;
          text-align: right;
          color: #a0a0a0;

          b {
            color: #000;
          }
        }
      }

      .payment_total {
        dt {
          width: 60%;
        }

        dd {
          width: 40%;
        }
      }
    }

    .border-box {
      border: 1px solid #ccc;
      padding: 20px;

      .button {
        margin: 0;
      }
    }

    .spacer {
      @media (max-width: 480px) {
        display: none;
      }
    }
  }

  .card_list {
    table {
      margin: 0 0 30px;
      padding: 0;
      width: auto;
    }

    tr {
      margin: 0;
      padding: 0;
    }

    td {
      margin: 0;
      padding: 10px 0;
      line-height: 20px;
    }

    .numbering {
      color: #fff;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      display: inline-block;
      border-radius: 10px;
      background: #000;
      font-style: normal;
      margin-right: 15px;
    }

    button {
      background: none;
      border: 0;
      margin: 0 10px;

      &.default {
        border: 1px solid #1dbeb9;
        border-radius: 10px;
        padding: 0 10px;
      }

      &.delete {
        color: #1dbeb9;
      }
    }

    span {
      color: #1dbeb9;
    }
  }

  .tips {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 5px;
    background: url('../images/mobile/General/btn_mark.png') center top no-repeat;
    background-size: 100%;
    cursor: pointer;
    border: none;
  }

  .tipsText {
    position: relative;
    top: 100%;
    left: 0;
    width: 300px;
    background: #fff;
    border: 1px solid #cccccc;
    box-shadow: 0px 0 5px #ddd;
    padding: 10px;
    z-index: 101;
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    max-height: 300px;
    overflow-y: auto;
  }
}
