.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 9990;
  display: none;

  &.active {
    display: unset;
  }
}

.changeVehicleRegMarkBlock {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  z-index: 9999;
  background-color: white;
  display: none;
  border: 5px solid #1dbeb9;
  max-width: 500px;

  &.active {
    display: unset;
  }

  .text {
    text-align: left;
    width: 100%;
    padding: 0;
  }

  .cancel {

    color: #1dbeb9;
    width: 20px;
    float: right;
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .input {

    margin-bottom: 1rem;
  }
}