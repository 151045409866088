.detailShare
{
    background: #e0e0e0;

    .content
    {
        margin: 0;

        .information
        {
            width: 60%;
            padding: 0;

            @media (max-width: 980px)
            {
                width: 100%;
            }

            > .text
            {
                h3
                {
                    margin: 0 0 5px 0;
                    padding: 0;
                    font-size: 28px;
                    font-weight: bold;
                    color: #000;
                }

                h4
                {
                    margin: 0 0 10px 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: bold;
                    color: #000;
                }
            }

            .super-wrapper
            {
                position: relative;

                &.active
                {
                    margin: 0 0 85px 0;
                    @media (max-width: 440px)
                    {
                        margin: 0 0 65px 0;
                    }
                }

                img
                {
                    width: 100%;
                }

                .clusterMap
                {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    float: none;
                }

                .verified
                {
                    background: $majorYellowGreen;
                    width: 35px;
                    height: 35px;
                    border-radius: 20px;
                    position: absolute;
                    top: 15px;
                    left: 15px;
                }

                .heart
                {
                    width: 32px !important;
                    height: 32px;
                    border-radius: 16px;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 101;
                }
            }

            > .landlord
            {
                display: table;
                width: 100%;

                @media (max-width: 840px)
                {
                    display: block;
                }

                > .fifty
                {
                    display: table-cell;
                    vertical-align: middle;

                    @media (max-width: 840px)
                    {
                        display: block;
                        width: 100%;
                    }

                    > .image
                    {
                        width: 65px;
                        height: 65px;
                        border-radius: 35px;
                        overflow: hidden;
                        float: left;

                        img
                        {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    > .text
                    {
                        width: calc(100% - 65px);
                        float: left;
                        padding: 0 20px 10px;

                        h3
                        {
                            margin: 0;
                            padding: 0;
                            color: $majorGreen;
                            font-size: 18px;
                        }
                    }

                    > .response
                    {
                        border-left: solid 1px #cccccc;
                        padding: 0 20px;

                        @media (max-width: 840px)
                        {
                            border: none;
                            border-top: solid 1px #cccccc;
                            padding: 20px 0;
                        }

                        .rate
                        {
                            color: #454545;
                            position: relative;
                            padding: 0 0 0 20px;
                            margin: 0 0 15px 0;
                            font-size: 14px;

                            &:before
                            {
                                content: ' ';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 10px;
                                height: 10px;
                                background: $majorYellowGreen;
                            }

                            span
                            {
                                color: $majorGreen;
                                font-weight: bold;
                            }
                        }

                        .time
                        {
                            color: #454545;
                            position: relative;
                            padding: 0 0 0 20px;
                            font-size: 14px;

                            &:before
                            {
                                content: ' ';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 10px;
                                height: 10px;
                                background: $majorYellowGreen;
                            }

                            span
                            {
                                color: $majorGreen;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }

        .book
        {
            width: 40%;
            padding: 0 0 0 40px;
            line-height: 1.3em;
            position: relative;
            z-index: 10;

            @media (max-width: 980px)
            {
                width: 100%;
                padding: 0;
            }

            .timepicker
            {
                &:first-of-type
                {
                    width: 45%;
                    margin-right: 5%;
                }

                &:last-of-type
                {
                    width: 45%;
                    margin-left: 5%;
                }
            }

            select
            {
                background: $majorGreen url('../images/arrow_down_w.png') center right 15px no-repeat;
                color: #fff;
                border: none;

                option
                {
                    background: #fff;
                    color: #000;

                    &:disabled
                    {
                        color: #ccc;
                    }
                }

                &.whiteBg
                {
                    background: #fff;
                    border: solid 1px #cccccc;
                    color: #444;
                }
            }

            .available-days,
            .unavailable-days
            {
                color: #434343;
                position: relative;
                padding: 0 30px 0 0;
                font-size: 14px;

                span
                {
                    color: $majorGreen;
                    font-weight: bold;
                }

                .check
                {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: 0 0 0 10px;
                    background: url('../images/mobile/04_list_a_space/btn_light_bulb_1.png') center center no-repeat;
                    background-size: 100%;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .instant
                {
                    display: inline-block;
                    width: 16px;
                    height: 24px;
                    margin: 0 0 0 10px;
                    background: url('../images/mobile/03_find_parking_space/icon_instant_booking.png') center center no-repeat;
                    background-size: 100%;
                    border-radius: 10px;
                    cursor: pointer;
                }


                .popup
                {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    min-width: 300px;
                    background: #fff;
                    border: 1px solid #cccccc;
                    box-shadow: 0px 0 15px #ddd;
                    padding: 15px 15px 5px;
                    z-index: 101;
                    margin-top: 10px;
                    max-height: 300px;
                    overflow-y: auto;

                    > dl
                    {
                        display: block;
                        margin: 0;
                        padding: 0;

                        dt
                        {
                            width: 25%;
                            float: left;
                            margin: 0 0 5px 0;
                            padding: 0 5px 0 0;
                            color: #a0a0a0;
                            font-weight: normal;
                        }

                        dd
                        {
                            width: 75%;
                            float: left;
                            margin: 0 0 5px 0;
                            padding: 0 0 0 5px;
                            text-align: right;
                            color: #a0a0a0;
                        }
                    }
                }
            }

            > dl
            {
                display: block;
                margin: 0;
                padding: 0;

                dt
                {
                    width: 50%;
                    float: left;
                    margin: 0 0 5px 0;
                    padding: 0 10px 0 0;
                    color: #a0a0a0;
                    font-weight: normal;

                    b
                    {
                        color: #000;
                    }
                }

                dd
                {
                    width: 50%;
                    float: left;
                    margin: 0 0 5px 0;
                    padding: 0 0 0 10px;
                    text-align: right;
                    color: #a0a0a0;

                    b
                    {
                        color: #000;
                    }
                }
            }

            .remarks
            {
                color: $majorGreen;
                margin: 0;
            }

            .notChargedYet
            {
                color: grey;
                text-align: center;
                margin: 0;
            }

            .verifiedGuarantee
            {
                tr
                {
                    td
                    {
                        vertical-align: middle;
                        padding: 5px 0 5px 20px;
                        font-size: 16px;
                        line-height: 1.4em;

                        &:first-child
                        {
                            padding: 5px 0 5px 0;
                            width: 38px;
                        }

                        a
                        {
                            color: $majorGreen;
                            font-size: 16px;
                            line-height: 1.4em;
                        }

                        span
                        {
                            //color: $majorGreen;
                        }
                    }
                }

                img
                {
                    width: 38px;
                    height: 38px;
                    margin: 0;
                }
            }

            .price
            {
                color: #717171;
                position: relative;
                display: inline-block;
                font-weight: bold;

                span
                {
                    color: $majorGreen;
                    font-size: 20px;
                    margin: 0 4px;
                }

                .instantBooking
                {
                    position: absolute;
                    top: 50%;
                    margin-top: -10px;
                    right: -22px;
                    width: 16px;
                    height: 24px;
                    background: url('../images/mobile/03_find_parking_space/icon_instant_booking.png') center center no-repeat;
                    background-size: 100%;
                }

                .bcode
                {
                    position: absolute;
                    top: 50%;
                    margin-top: -12px;
                    right: -54px;
                    width: 24px;
                    height: 24px;
                    background: url('../images/mobile/03_find_parking_space/smart_pass_icon.png') center center no-repeat;
                    background-size: 100%;
                }

                .popup
                {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    min-width: 300px;
                    background: #fff;
                    border: 1px solid #cccccc;
                    box-shadow: 0px 0 15px #ddd;
                    padding: 15px 15px 5px;
                    z-index: 101;
                    margin-top: 10px;
                    max-height: 300px;
                    overflow-y: auto;

                    > dl
                    {
                        display: block;
                        margin: 0;
                        padding: 0;

                        dt
                        {
                            width: 25%;
                            float: left;
                            margin: 0 0 5px 0;
                            padding: 0 5px 0 0;
                            color: #a0a0a0;
                            font-weight: normal;
                        }

                        dd
                        {
                            width: 75%;
                            float: left;
                            margin: 0 0 5px 0;
                            padding: 0 0 0 5px;
                            text-align: right;
                            color: #a0a0a0;
                        }
                    }
                }
            }


            h3
            {
                color: #717171;
                font-size: 16px;
                margin: 0 0 10px 0;
            }

            // left and right
            .wrap
            {
                margin: 0 0 0 -25px;
                display: table;
                width: calc(100% + 25px);

                @media (max-width: 640px)
                {
                    margin: 0 0 0 -15px;
                    width: calc(100% + 15px)
                }

                > div
                {
                    display: table-cell;
                    vertical-align: middle;

                    // @media (max-width: 640px ) { display:block; width:100%; }
                }

                > div > div
                {
                    margin: 0 0 20px 25px;

                    @media (max-width: 640px)
                    {
                        margin: 0 0 20px 15px;
                    }
                }
            }

            // Detail Monthly
            &.full
            {
                width: 100%;
                padding: 0;
            }

            .typeIcon
            {

                background-color: #1dbeb9;
                height: 56px;
                position: relative;
                left: 3px;
                width: 38px;
                border-radius: 3px;

                img
                {

                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                }
            }
        }

        // react-slick
        .slick-slider
        {
            position: absolute;
            top: 0;
            width: 100%;

            .slick-slide
            {
                > div > div
                {
                    display: block !important;
                }

                img
                {
                    display: block;
                    width: 100%;
                }
            }

            .slick-arrow
            {
                &.slick-prev
                {
                    left: 15px;
                    top: 50%;
                    z-index: 101;

                    &:before
                    {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background: url('../images/slider-arrow-left.png') center center no-repeat;
                    }
                }

                &.slick-next
                {
                    right: 15px;
                    top: 50%;
                    z-index: 101;

                    &:before
                    {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background: url('../images/slider-arrow-right.png') center center no-repeat;
                    }
                }
            }

            .slick-dots
            {
                top: 100%;
                margin: 20px 0 0 -10px;
                text-align: left !important;
                width: calc(100% + 10px);

                li
                {
                    width: 25%;
                    max-width: 100px;
                    height: auto;
                    display: block;
                    margin: 0;
                    float: left;

                    > div
                    {
                        margin: 0 0 0 10px;

                        a
                        {
                            display: block;

                            img
                            {
                                -webkit-filter: grayscale(100%);
                                filter: grayscale(100%);
                                max-width: 100%;
                            }
                        }
                    }

                    &.slick-active
                    {
                        img
                        {
                            -webkit-filter: grayscale(0);
                            filter: grayscale(0);
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
