.home {

  position: relative;
  padding: 0;
  height: 750px;

  @media screen and (min-width: 640px) {

    height: 850px;
  }

  .slick-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;

        > div {
          height: 100%;

          > div {
            height: 100%;

            > div {
              height: 100%;
            }
          }
        }
      }
    }

    .banner {

      position: relative;
      background-size: cover;
      background-position: left bottom;
      background-repeat: no-repeat;
      height: 750px;

      @media screen and (min-width: 640px) {

        height: 850px;
      }
    }

    .bannerText {

      position: relative;
      margin: 0 auto;
      max-width: 100%;

      @media screen and (max-width: 640px) {

        padding-top: 3.5rem;
      }
    }

    .bannerOne {

      background-image: url('../images/desktop/banner/P1(BG)M.jpg');

      @media screen and (min-width: 640px) {

        background-image: url('../images/desktop/banner/P1(BG).jpg');
      }
    }

    .bannerTwo {

      background-image: url('../images/desktop/banner/P2(BG)M.jpg');

      @media screen and (min-width: 640px) {

        background-image: url('../images/desktop/banner/P2(BG).jpg');
      }
    }

    .bannerThree {

      background-image: url('../images/desktop/banner/P3(BG)M_final.jpg');

      @media screen and (min-width: 640px) {

        background-image: url('../images/desktop/banner/P3(BG).jpg');
      }
    }
  }

  .slick-dots {

    bottom: 25px;
  }

  .bannerText1 {

    margin-top: 20px;
  }

  .bannerText2 {

    margin-top: 50px;
  }

  .bannerText3 {

    margin-top: 120px;
  }

  .containerMain {

    top: 700px;

    .wrapper-search {

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
      width: 100%;

      @media screen and (min-width: 640px) {

        bottom: 130px;
        width: 700px;
      }
    }
  }

  .wrapper-tabs {

    ul.nav-tabs {
      margin: 0;

      @media (max-width: 640px) {
        display: table;
        width: 100%;
      }

      li {
        margin: 0;
        padding: 20px 40px;

        @media (max-width: 640px) {
          float: none;
          display: table-cell;
          width: 50%;
          padding: 20px 20px;
          vertical-align: middle;
        }

        a {
          color: white;
          font-size: 24px;
          font-weight: bold;
          text-transform: uppercase;
          text-shadow: 2px 2px 2px #ccc;
          line-height: 1.2em;

          @media (max-width: 640px) {
            font-size: 20px;
            text-align: center;
          }
          @media (max-width: 480px) {
            font-size: 16px;
          }
        }

        &.active {
          background: white;

          a {
            color: $majorGreen;
            background: none;
            text-shadow: none;
          }
        }
      }
    }
  }

  h3 {
    color: #000;
    text-transform: uppercase;
    margin: 0 0 30px 0;
    font-size: 32px;
    line-height: 1.2em;
    @media (max-width: 640px) {
      font-size: 24px;
      margin: 0 0 20px 0;
    }

    .sep-0 {
      display: none;
      @media (max-width: 640px) {
        display: block;
      }
    }
  }

  .wrapper-hot {
    padding: 40px 40px 0 40px;
    background: #fff;

    @media (max-width: 640px) {
      padding: 20px 20px 0 20px;
    }

    .hot {
      margin: 0 0 0 -20px;

      .toHide {
        @media (max-width: 840px) {
          display: none;
        }
      }

      > div {
        @media (max-width: 840px) {
          width: 100%;
        }
      }

      > div > div {
        margin: 0 0 20px 20px;

      }

      .moreHotSpaces {
        color: #000;
        font-size: 16px;
      }
    }
  }

  .show_for_large {

    display: none;

    @media screen and (min-width: 640px) {

      display: block;
    }
  }

  .show_for_small {

    display: none;

    @media screen and (max-width: 640px) {

      display: block;
    }
  }
}

.wrapper-homeBottom {
  display: none;

  @media (max-width: 980px) {
    display: block;
  }

  .wrapper-HowItWork {
    background: #eff5f5;

    .HowItWork {
      max-width: 1240px;
      margin: 0 auto;
      padding: 45px 20px;

      h3 {
        color: #000;
        text-transform: uppercase;
        margin: 0 0 30px 0;
        font-size: 32px;
        line-height: 1.2em;
        @media (max-width: 640px) {
          font-size: 24px;
          margin: 0 0 20px 0;
        }

        .sep-0 {
          display: none;
          @media (max-width: 640px) {
            display: block;
          }
        }
      }

      > div {
        @media (max-width: 980px) {
          margin-left: -20px;
          margin-right: -20px;
        }

        img {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .wrapper-featured {
    background: #ecf0f1;

    .featured {
      max-width: 1240px;
      margin: 0 auto;
      padding: 45px 20px;

      > div {
        img {
          width: 100%;
          max-width: 558px;
          margin: 0 auto;
        }
      }
    }
  }
}

.wrapper-homeMiddle {
  position: relative;
  background: #1dbeb9;
  z-index: 1;

  h3 {
    color: #000;
    //text-transform: uppercase;
    margin: 0 0 30px 0;
    font-size: 35px;
    line-height: 1.3em;
    @media (max-width: 640px) {
      font-size: 24px;
      margin: 0 0 20px 0;
    }

    .sep-0 {
    }
  }

  .wrapper-one {
    position: relative;
    background: #eff5f5;
    z-index: 3;
    min-width: 320px;
    @media (max-width: 980px) {
      width: 100% !important;
    }

    .one {
      margin: 0 auto;;

      > div {
        display: block;
        float: right;
        min-width: 320px;
        @media (max-width: 980px) {
          width: 100% !important;
          float: none;
        }

        .wrapper-benefitsOf {
          padding: 60px 45px 40px 20px;
          background: #eff5f5;

          @media (max-width: 980px) {
            padding: 60px 20px 20px 20px;
          }

          .learnMore {
            color: #000;
            text-transform: uppercase;
            font-size: 20px;
            @media (max-width: 640px) {
              font-size: 18px;
            }
          }
        }

        .wrapper-howItWorks {

          //padding: 20px;
          background: #eff5f5;

          @media (min-width: 980px) {

            padding: 60px 45px 50px 20px;
          }

          .title {

            padding-left: 20px;
          }

          .howItWorks {

            text-align: center;
            position: relative;
            overflow: hidden;
            padding-top: 86.25%;
            width: 100vw;
            max-width: 1200px;

            .youtubeFrame {

              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 0;
              padding: 20px;
            }
          }
        }
      }
    }
  }

  .wrapper-two {
    background: #becd32;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    @media (max-width: 980px) {
      position: relative;
      top: auto;
    }

    .two {
      max-width: 1240px;
      margin: 0 auto;
      text-align: right;

      @media (max-width: 1280px) {
        max-width: 1200px;
      }

      > div {
        display: block;
        float: right;
        width: 32%;
        text-align: left;

        @media (max-width: 980px) {
          width: 100%;
        }

        .wrapper-whatsHot {
          padding: 60px 20px 20px 45px;
          background: #becd32;

          @media (max-width: 980px) {
            padding: 45px 20px;
          }

          // react-slick
          .slick-slider {
            max-width: 518px;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }

          .slick-dots {
            position: relative;
            bottom: auto;
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }

  .wrapper-three {
    background: #1dbeb9;
    position: absolute;
    top: 515px;
    width: 100%;
    z-index: 2;

    @media (max-width: 980px) {
      position: relative;
      top: auto !important;
    }

    .three {
      max-width: 1240px;
      margin: 0 auto;
      text-align: right;

      @media (max-width: 1280px) {
        max-width: 1200px;
      }

      > div {
        display: block;
        float: right;
        width: 32%;
        text-align: left;

        @media (max-width: 980px) {
          width: 100%;
        }

        .wrapper-ourCustomers {
          padding: 60px 20px 20px 45px;
          background: #1dbeb9;

          @media (max-width: 980px) {
            padding: 45px 20px;
          }

          .ourCustomers {
            text-align: left;

            img {
              width: 100%;
              max-width: 304px;
              margin: 0 auto 30px;
            }

            p {
              color: #fff;
            }

            h4, h5 {
              color: #fff;
              font-weight: bold;
            }

            .slick-slider {
              padding: 0 30px;
            }

            .slick-arrow {
              top: 152px;
              margin-top: -10px;
              z-index: 2;

              &.slick-prev {
                left: 0px;
              }

              &.slick-next {
                right: 0px;
              }
            }
          }
        }
      }
    }
  }

  .partnersContainer {
    position: relative;
    margin: 10px 40px;
    width: 100%;
    height: 250px;
    overflow-x: auto;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .partnersContainer::-webkit-scrollbar {
    display: none;
  }

  .partnersArrow {
    position: absolute;
    top: calc(50% - 12.5px);
    width: 25px;
    object-fit: contain;
    cursor: pointer;
    z-index: 10;
  }

  .partnersArrowLeft {
    left: 0;
  }

  .partnersArrowRight {
    right: 0;
  }
}
