.hotSpaces { background:#cee0ea;

    .content{ margin:0 0 0 -20px;

        > div {
            @media (max-width: 1024px) { width:50%; }
            @media (max-width: 840px) { width:100%; }
        }
        .sepThree { display:block; 
            @media (max-width: 1024px) { display:none; }
        }
        .sepTwo { display:none;
            @media (max-width: 1024px) { display:block; }
        }

        > div > div { margin:0 0 20px 20px;

        }
    }
}
