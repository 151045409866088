.helpCenter {

  background: #19bcb9;
  background-image: url("../images/desktop/banner/Help Center.jpg");
  background-repeat: no-repeat;
  background-size: 100%;

  //Tab
  .wrapper-tabs {

    .tab-content {

      .inner {

        background-color: #FFF;
        padding: 0;
      }
    }
  }

  //Accordion
  .accordion {

    position: relative;

    .accordion__title {

      cursor: pointer;
    }

    .accordion__item {

      position: relative;
      margin-bottom: 1.5rem;

      .accordion__title {

        background-color: #f3f3f3;
        padding: 1rem;

        > h3 {

          margin: 0;
        }

        &[aria-selected="true"] {

          background-color: #1dbeb9;

          > h3 {

            color: #FFF;
          }
        }
      }

      .accordion__body {

        padding: 1rem;
        background-color: #f3f3f3;
      }

      .youtube,
      .image {

        text-align: center;
      }

      .youtube {

        .youtubeBlock {

          position: relative;
          width: 100%;
        }
      }
    }
  }

  //Show more
  .wrapper-show-more {

    padding-bottom: 0;
  }
}