.clusterMap {
  // TODO: may need to update the resolution
  width: calc(100% - 600px);
  height: 840px;
  float: right;

  @media (max-width: 980px) {
    width: 100%;
  }

  .containerMain {
    &.fullWidth {
      padding: 0;
      max-width: 100%;
      // min-height: 840px;
    }
  }

  .marker {
    background: #1dbeb9;
    width: 84px;
    height: 32px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px;
    color: white;
    box-sizing: border-box;

    .icon {
      background: url(../images/mobile/03_find_parking_space/icon_letspark_white.png) no-repeat;
      width: 17px;
      height: 23px;
      background-position: top;
    }

    .bcode {
      background: url('../images/mobile/03_find_parking_space/smart_pass_icon.png') no-repeat;
      width: 23px;
      height: 23px;
      background-size: 100%;
      background-position: top;
    }

    .price {
      font-size: 14px;
    }

    &.active {
      background: white;
      border: 2px solid #1dbeb9;
      color: #1dbeb9;

      .icon {
        background: url(../images/mobile/03_find_parking_space/icon_letspark_over.png) no-repeat;
      }
    }
  }

  .info-window {
    position: relative;
    //display: flex;
    background: white;
    width: 460px;
    min-height: 140px;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;


    .info-window-space {
      display: block;
      position: relative;
      min-height: 140px;
      width: 460px;
      border-top: 1px solid #ccc;

      &:first-child {
        border: 0;
      }
    }

    .info-window-wrapper {
      padding: 10px 10px 10px 180px;
    }

    .info-window-img-block {
      position: absolute;
      left: 10px;
      top: 10px;
      display: inline-block;
      width: 160px;
      height: 120px;

      img {
        display: block;
      }


      //position: relative;
      //width: 140px;

      .info-window-img {
        background-size: cover;
        display: inline-block;
        width: 160px;
        height: 120px;

        //position: absolute;
        //background-repeat: no-repeat;
        //background-size: 100%;
        //background-position: center;
        //width: 100%;
        //height: 100%;
      }
    }

    .info-detail {
      display: block;
      width: 280px;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-evenly;

      .info-detail-address {
        font-size: 18px;
        font-weight: bold;
        flex: 1;

        h3 {
          padding: 0;
          margin: 0 0 5px 0;
          font-size: 16px;
          line-height: 1;
        }

        h4 {
          padding: 0;
          margin: 0 0 15px 0;
          font-size: 14px;
          line-height: 1;
        }
      }

      .info-detail-reviews {
        display: flex;
        align-items: center;
        flex: 1;

        .review-rating {
          i {
            display: inline-block;
            background-image: url(../images/mobile/03_find_parking_space/icon_reivew2.png);
            background-size: 16px;
            width: 16px;
            height: 16px;
            margin-right: 3px;

            &.on {
              background-image: url(../images/mobile/03_find_parking_space/icon_reivew1.png);
            }
          }
        }

        .review-count {
        }

        .button {
          background: #c0d032;
          position: absolute;
          right: 25px;
          bottom: 0px;
          padding: 10px 15px;
          line-height: 1em;
          font-size: 12px;
        }
      }
    }
  }
}
