.memberLogin { background:#e0e0e0;
    
    .content{ margin:0 auto; max-width:380px;

        .door { display:inline-block; width:52px; height:52px; margin: 0 0 20px 0; background:url('../images/mobile/02_membership/00_signin/icon_login.png'); background-size:100%; }

        h2 { margin:0 0 5px 0; font-size:30px; font-weight:bold; color:#000; }

        p { margin:0 0 15px 0; font-size:15px; color:#000; }

        dt { display:none; }

        dd { width:100%; }
    }
}
