.find-your-space {
  // min-height: 840px;

  @media (max-width: 980px) {
    min-height: 0px;
  }

  &.fullWidth {
    padding: 0;
  }

  .containerMain {

    &.fullWidth {
      padding: 0;
      max-width: 100%;
      // min-height: 840px;

      &.mapView {
        .wrapper {
          padding: 20px;
          display: none;

          @media (max-width: 980px) {
            display: block;
          }
        }
      }
    }
  }

  .content {

    .toggleFilter {
      margin: 0 0 40px 0;
      @media (max-width: 980px) {
        display: none;
      }
    }

    .search {
      padding: 10px 20px 0 20px;
      border: solid 1px #ccc;

      @media (max-width: 980px) {
        display: block;
      }

      .wrap {
        margin: 0 0 0 -30px;

        > div.fifty {
          @media (max-width: 640px) {
            width: 100%;
          }
        }

        .sep-0 {
          border-bottom: solid 1px #ccc;
          margin: 0 0 10px 30px;
        }

        > div > div {
          margin: 0 0 10px 30px;
          position: relative;

          .vertical-sep {
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            right: -15px;
            z-index: 2;
            background: #ccc;
            @media (max-width: 640px) {
              display: none;
            }
          }

          select,
          input {
            border: none;
            padding: 15px 0;
          }

          h5 {
            color: $majorYellowGreen;
            text-transform: uppercase;

            &.toHide {
              @media (max-width: 640px) {
                display: none;
              }
            }
          }

          .button {
            width: 100%;
            background: $majorYellowGreen;
            padding: 25px;
            font-size: 18px;
          }

          .bell {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              display: block;
              width: 1px;
              height: 100%;
              right: -10px;
              top: 0;
              background: #ccc;
            }

            display: inline-block;
            width: 30px;
            float: left;
            margin: 13px 20px 0 0;
          }

          input[type=text] {
            width: calc(100% - 85px);
            float: left;
          }

          .magnifier {
            width: 20px;
            height: 20px;
            float: left;
            margin: 18px 0 0 10px;
            cursor: pointer;
          }
        }
      }
    }

    .search2 {
      padding: 0 20px;
      border: solid 1px #ccc;

      @media (max-width: 840px) {
        padding: 15px;
      }

      .wrap {
        margin: 0 0 0 -30px;

        > div.oneFourth {
          @media (max-width: 840px) {
            width: 50%;
          }
          @media (max-width: 640px) {
            width: 100%;
          }
        }

        > div.fifty {
          @media (max-width: 840px) {
            width: 100%;
          }
        }

        .sep-0 {
          display: none;
          border-bottom: solid 1px #ccc;
          margin: 0 0 10px 30px;
          @media (max-width: 840px) {
            display: block;
          }
        }

        > div > div {
          margin: 0 0 0 30px;
          position: relative;

          .vertical-sep {
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            right: -15px;
            z-index: 2;
            background: #ccc;
            @media (max-width: 840px) {
              height: calc(100% - 15px);
            }
            @media (max-width: 640px) {
              display: none;
            }

            &.special {
              @media (max-width: 840px) {
                display: none;
              }
            }
          }

          select,
          input {
            border: none;
            padding: 15px 0;
          }

          h5 {
            color: $majorYellowGreen;
            text-transform: uppercase;

            &.toHide {
              @media (max-width: 640px) {
                display: none;
              }
            }
          }

          .button {
            width: 100%;
            background: $majorYellowGreen;
            padding: 25px;
            font-size: 18px;
          }

          .bell {
            position: relative;
            display: inline-block;
            width: 30px;
            float: left;
            margin: 13px 20px 0 0;
            // &:before { content : ''; position:absolute; display:block; width:1px; height:100%; right:-10px; top:0; background:#ccc; }

          }

          input[type=text] {
            width: calc(100% - 85px);
            float: left;
          }

          .magnifier {
            width: 20px;
            height: 20px;
            float: left;
            margin: 18px 0 0 10px;
            cursor: pointer;
          }
        }
      }
    }

    .addressError {

      float: right;
    }

    .results {
      background: #1dbeb9;
      margin: 0 0 20px 0;
      padding: 15px 20px;
      color: #fff;
      display: none;
      @media (max-width: 980px) {
        display: block;
      }
    }
  }

  .toggleViews {
    z-index: 1;

    &.absolute {
      position: absolute;
      top: 20px;
      right: 20px;
    }


    > ul {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding: 0;
      overflow: hidden;

      > li {
        display: block;
        margin: 0;
        padding: 0;
        float: left;
        text-align: center;
        color: #fff;
        background: #f3f3f3;
        text-transform: uppercase;
        font-size: 13px;
        cursor: pointer;

        &.filter {
          margin-right: 20px;
          @media (max-width: 980px) {
            display: none;
          }
        }

        a {
          display: block;
          padding: 10px 25px;
          color: #808080;

          &:hover,
          &.active {
            color: #fff;
            background: $majorGreen;
          }
        }
      }
    }
  }
}

.desktop_map_until {

  .react-datepicker-popper {

    left: -88px !important;
  }
}

.spaceEditPopup {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  z-index: 9999;
  background-color: white;
  display: none;
  border: 5px solid #1dbeb9;
  max-width: 400px;
  text-align: center;

  &.active {

    display: inline-block;
  }

  .edit {

  }
}

.reviewBlock {

  position: relative;

  @media screen and (min-width: 1024px) {

    padding: 0 2rem;
  }

  .bookerReview {

    margin-top: 1.5rem;

    .userImage {

      img {

        border-radius: 100%;
        width: 65px;
        height: 65px;
      }
    }

    h4 {
      display: inline-block;
    }
  }

  .ownerReview {

    margin-top: 1.5rem;
  }
}