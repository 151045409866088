.landlordProfile {
  background: #e0e0e0;

  .content {
    margin: 0;

    .landlord {
      width: 240px;
      padding: 0 50px 0 0;
      line-height: 1.3em;

      > .image {
        width: 120px;
        border-radius: 60px;
        overflow: hidden;
        margin: 50px 0 10px 0;

        img {
          width: 100%;
          height: 120px;
          display: block;
        }
      }

      h3 {
        color: #000;
        font-size: 20px;
        margin: 0 0 10px 0;
      }

      .memberSince {
        color: #6f6f6f;
        margin: 0 0 15px 0;

        span {
          white-space: nowrap;
        }
      }

      .Verified {
        color: #6f6f6f;
        padding: 0 0 0 30px;
        margin: 0 0 15px 0;
        position: relative;

        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $majorYellowGreen;
        }
      }

      .response {
        border: solid 1px #ccc;
        padding: 15px 20px;
        color: #434343;

        .rate {
          color: #454545;
          position: relative;
          padding: 0 0 0 20px;
          margin: 0 0 10px 0;
          font-size: 14px;

          &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            background: $majorYellowGreen;
          }

          span {
            color: $majorGreen;
            font-weight: bold;
          }
        }

        .time {
          color: #454545;
          position: relative;
          padding: 0 0 0 20px;
          font-size: 14px;

          &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            background: $majorYellowGreen;
          }

          span {
            color: $majorGreen;
            font-weight: bold;
          }
        }
      }
    }

    .profile {

      width: calc(100% - 240px);
      padding: 0;

      .tab-content {
        background: #fff;
        padding-top: 5px;

        .dialog {
          background: #f3f3f3;
          padding: 25px;

          > .image {
            width: 70px;
            float: left;
            border-radius: 35px;
            overflow: hidden;

            img {
              width: 100%;
              height: 70px;
              display: block;
            }
          }

          > .text {
            width: calc(100% - 70px);
            float: left;
            padding: 0 0 0 10px;
            padding: 5px 0 0 15px;

            > .username {
              color: #000;
              font-weight: bold;
            }

            > .date {
              color: #6c6c6c;
              margin: 0 0 0 10px;
            }

            > .reply {
              color: $majorGreen;
              font-size: 14px;
              margin: 0 0 0 10px;
            }

            .button {
              margin: 5px 0;
            }
          }

          .sub-dialog {

            > .image {
              width: 50px;
              float: left;
              border-radius: 25px;
              overflow: hidden;

              img {
                width: 100%;
                height: 50px;
                display: block;
              }
            }

            > .text {
              width: calc(100% - 150px);
              float: left;
              padding: 10px 0 0 15px;

              > .username {
                color: #000;
                font-weight: bold;
              }

              > .date {
                color: #6c6c6c;
                margin: 0 0 0 10px;
              }
            }
          }

          .moreComments {
            display: block;
            text-align: center;
            color: $majorGreen;
            font-size: 14px;
          }
        }
      }

      .details {

      }
    }
  }
}

.mobile-full {

  @media screen and (max-width: 840px) {

    width: 100% !important;
  }
}