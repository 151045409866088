.memberRenewal {

  overflow: auto;

  button {
    color: $majorGreen;
    background-color: transparent;
    border: 1px solid $majorGreen;
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    padding: 10px 10px;
    margin: 5px;
  }

  button:hover {
    background-color: $majorGreen;
    color: white;
  }

  th {
    text-align: center;
  }

  td {
    vertical-align: middle;
  }

  .message {
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
    line-height: 2;
  }

  .main_table {
    border: 1px solid lightgrey;
    font-size: 13px;
    min-height: 450px;
    min-width: 1000px;
  }

  .main_table_header tr th{
    padding: 5px;
    background-color: lightgrey;
    font-weight: bolder;
  }

  .main_table_row {
    text-align: center;
    border: 1px solid lightgrey;
    border-bottom: none;
    vertical-align: center;
  }

  .main_table_row:hover {
    background-color: #ebebeb;
  }

  //Service

  .collapse {
    padding: 20px;
    background-color: #ebebeb;
    margin: 10px;
    border-radius: 5px;
    min-width: 800px;
  }

  .details tr {
    line-height: 2.5;
  }

  .configuration {
    font-size: 18px;
    font-weight: bolder;
  }

  .sub_section {
    vertical-align: top;
  }

  .invoice {
    background-color: white;
    border-radius: 5px;
    border: none;
    font-size: 13px;
    min-width: 450px;

    thead {
      border-bottom: 1px solid lightgrey;
    }

    td{
      padding: 10px;
      text-align: center;
    }
  }

  //Invoice

  .invoice_table {
    min-width: 1000px;
  }

  .invoice_table td {
    padding: 5px 0 5px 0;
  }

}
