.location-search-input {
	position: relative;

	.autocomplete-dropdown-container {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		border-radius: 0 0 2px 2px;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;

		.suggestion-item {
			padding: 8px;
			text-align: left;
			background-color: #fff;
			cursor: pointer;
		}

		.suggestion-item--active {
			background-color: #fafafa;
		}
	}
}