._memberMobileVerification { background:#e0e0e0;
    
    .content{ margin:0 auto; max-width:380px;

        .door { display:inline-block; width:30px; height:42px; border:solid 2px $majorGreen; margin: 0 0 20px 0; }

        h2 { margin:0 0 5px 0; font-size:30px; font-weight:bold; color:#000; }

        p { margin:0 0 15px 0; font-size:16px; color:#000; }

        dt { display:none; }

        dd { width:100%; }
    }
}
