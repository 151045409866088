.terms,
.licenseAgreement {

  ol {

    > li {

      > ol {

        list-style-type: lower-roman;

        > li {

          > ol {

            
            list-style-type: lower-alpha;
          }
        }
      }
    }
  }

  //ol {
  //
  //  counter-reset: item;
  //  margin: 1rem 0;
  //
  //  > li {
  //
  //    display: block;
  //    margin: 1rem 0;
  //
  //    &:before {
  //      content: counters(item, ".") " ";
  //      counter-increment: item;
  //    }
  //
  //    > ul {
  //
  //      list-style-type: lower-alpha;
  //      margin: 1rem 0;
  //
  //      > li {
  //
  //        margin: 0.5rem 0;
  //      }
  //    }
  //
  //    > ol {
  //
  //      > li {
  //
  //        &:before {
  //
  //          margin-left: -25px;
  //        }
  //
  //        > ul {
  //
  //          > li {
  //
  //            margin-left: -25px;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
}