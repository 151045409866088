.memberResetPassword
{
    .containerMain
    {
        h1
        {
            color:$majorGreen;
            font-weight: 700;
        }
    }
}