.wrapperMemberMenuDesktop { overflow-x:auto; background:#fff; -webkit-overflow-scrolling: touch; position:absolute; right:100%; top:0; 
    
    @media (max-width:1500px) { display:none; }

    .memberMenuDesktop { list-style:none; margin:0; padding:0; display:block; text-align:center; width:100%; border-right: solid 1px #e1e1e1;

        >li { display: block; background:#fff; border-top: solid 1px #e1e1e1;

            &:first-child { border-top:none; }

            >a { display:block; background:#fff; padding:15px 10px; width:120px;

                &.active{
                    background:$majorGreen; color:#fff;
                    h5 { color:#fff;}
                    span { background-position: center 105px !important; 
                        @media (max-width: 640px) { background-position: center 64px !important; }
                    }
                }

                >span { display:inline-block; width:50px; height:50px; background-size:240% !important; background-position: center -15px !important;
                    
                    @media (max-width: 640px) { width:30px; height:30px; background-position: center -8px !important; }

                    &.wishlist { background:url('../images/mobile/02_membership/sub_nav/btn_wishlist.png')}
                    &.bookings { background:url('../images/mobile/02_membership/sub_nav/btn_bookings.png')}
                    &.listings { background:url('../images/mobile/02_membership/sub_nav/btn_listings.png')}
                    &.history { background:url('../images/mobile/02_membership/sub_nav/btn_history.png')}
                    &.profile { background:url('../images/mobile/02_membership/sub_nav/btn_profile.png')}
                    &.credit { background:url('../images/mobile/02_membership/sub_nav/btn_wishlist.png')}
                    &.renewal { background:url('../images/mobile/02_membership/sub_nav/btn_renewal.png')}
                    &.setting { background:url('../images/mobile/02_membership/sub_nav/btn_setting.png')}
                }
                >h5 { color:#747474; font-size:14px; margin:0; 
                    @media (max-width: 640px) { font-size:12px; }
                }
            }
        }
    }
}

.wrapperMemberMenuMobile { max-width: 1200px; margin:0 auto; overflow-x:auto; background:#fff; -webkit-overflow-scrolling: touch; display:none; 
    
    @media (max-width:1500px) { display:block; }

    .memberMenuMobile { list-style:none; margin:0; padding:0; display:table; text-align:center; width:100%; min-width:640px; margin:0 auto;
        
        @media (max-width: 640px) { min-width:460px; }

        >li { display: table-cell; background:#fff; border-right:solid 1px #e1e1e1; border-top: solid 1px #e1e1e1; border-bottom: solid 1px #e1e1e1; width:17%;

            &:first-child { border-left:solid 1px #e1e1e1; }

            >a { display:block; background:#fff; padding:10px;


                &.active{
                    background:$majorGreen; color:#fff;
                    h5 { color:#fff;}
                    span { background-position: center 105px !important; 
                        @media (max-width: 640px) { background-position: center 64px !important; }
                    }
                }

                >span { display:inline-block; width:50px; height:50px; background-size:240% !important; background-position: center -15px !important;
                    
                    @media (max-width: 640px) { width:30px; height:30px; background-position: center -8px !important; }

                    &.wishlist { background:url('../images/mobile/02_membership/sub_nav/btn_wishlist.png')}
                    &.bookings { background:url('../images/mobile/02_membership/sub_nav/btn_bookings.png')}
                    &.listings { background:url('../images/mobile/02_membership/sub_nav/btn_listings.png')}
                    &.history { background:url('../images/mobile/02_membership/sub_nav/btn_history.png')}
                    &.profile { background:url('../images/mobile/02_membership/sub_nav/btn_profile.png')}
                    &.credit { background:url('../images/mobile/02_membership/sub_nav/btn_wishlist.png')}
                    &.renewal { background:url('../images/mobile/02_membership/sub_nav/btn_renewal.png')}
                    &.setting { background:url('../images/mobile/02_membership/sub_nav/btn_setting.png')}
                }
                >h5 { color:#747474; font-size:14px; margin:0; 
                    @media (max-width: 640px) { font-size:12px; }
                }
            }
        }
    }
}