.discover {
  background: #041617 url('../images/desktop/banner/Discover.jpg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 0px;

  .content {
    margin: 0 0 0 -20px;

    > div {
      @media (max-width: 840px) {
        width: 100%;
      }
    }

    > div > div {
      margin: 0 0 20px 20px;

    }

    .original {
      width: auto;
    }

    .marginLeft {
      margin-left: 20px;
    }

    .spacer {
      @media (max-width: 840px) {
        display: none;
      }
    }

    .mobileApplication img {
      @media (max-width: 980px) {
        width: 100%;
      }
    }
  }
}