.media {
  background: #01978c url('../images/desktop/banner/disclaimer_cover.jpeg') center top no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 0px;
  @media (max-width: 640px) {
    margin-top: -1px;
  }

  .filter {
    margin: 0 0 30px -30px;

    @media (max-width: 1024px) {
      margin: 0 0 10px -20px;
    }

    > div {
      @media (max-width: 840px) {
        width: 100%;
      }
    }

    > div > div {
      margin: 0 0 0 30px;

      @media (max-width: 1024px) {
        margin: 0 0 10px 20px;
      }

      input {
        width: 100%;
        border: solid 1px #cccccc;
        border-radius: 3px;
        padding: 10px 20px;
        font-size: 20px;
        color: #989898;
      }

      .text {
      }

      .sortby {
      }
    }
  }

  .content {
    margin: 0 0 0 -20px;

    > div {
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 840px) {
        width: 100%;
      }
    }

    .sepThree {
      display: block;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .sepTwo {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }

    > div > div {
      margin: 0 0 20px 20px;

    }
  }
}

.mediaDetail {

  h3 {
    margin: 0 0 10px 0;
  }

  .date {
    color: #a0a0a0;
    margin: 0 0 30px 0;
  }

  img {
    max-width: 100%;
  }
}
